@import '../../vars.scss';

.posts {
    header {
        text-align: center;
        margin-bottom: 30px;
    }

    h1 {
        margin-bottom: 20px;
    }
}

.articleYear {
    margin-bottom: 20px;
    
    hr {
        margin-bottom: 10px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.article {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 6px;

    &:hover {
        p {
            border-bottom: 2px solid red;
            margin-bottom: -2px;        
        }
    }

    .date {
        font-size: 0.7rem;
        text-align: center;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 600px) {
    .article {
        font-size: 0.8rem;
    }

    .article .date {
        display: none;
    }
}
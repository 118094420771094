@import '../../vars.scss';

.works {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.projects {
    display: grid;
    gap: 40px;

    grid-template-columns: 1fr 1fr;
}

.card {
    width: 400px;
    height: 400px;

    display: flex;
    flex-direction: column;

    border-radius: $border-radius;
    box-shadow: $box-shadow;

    transition: $transition;

    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 2px 2px #00000040
    }
}

.card-top {
    flex: 1;
    
    padding: 10px;

    p {
        font-size: 0.7rem;
    }
}

.card-middle {
    flex: 4;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex: 2;

    padding: 10px;

    font-size: 0.8rem;

    p {
        text-overflow: ellipsis;
        max-height: 40px;
    }

    .links {
        display: flex;
        justify-content: flex-end;
        letter-spacing: 1px;
        gap: 8px;
        
        > * {
            transition: $transition;
        }

        > *:hover {
            transform: scale(1.01);
        }
    }
}

@media (max-width: 1100px) {
    .projects {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 700px) {
    .card {
        height: 300px;
        width: 300px;
    }
}
@import '../../vars.scss';

$dark-mainText: rgba(216, 213, 213, 0.893);
$dark-secondaryText: #787692;
$dark-background: #33314e;
$dark-secondarybackground: #3d3b57;
$dark-accent: #ffd700;
$dark-hover: rgba(128, 128, 128, 0.3);

$light-mainText: #1a202c;
$light-secondaryText: #787692;
$light-background: rgb(240, 231, 219);
$light-secondarybackground: rgb(244 237 228);
$light-accent: #c85a6e; 
$light-hover: rgba(128, 128, 128, 0.3);

.dark {
    &.page {
        background-color: $dark-background;
        color: $dark-mainText;

        .themeToggle {
            background: $dark-accent;
            color: $dark-background;
        }

        input, textarea {
            background-color: $dark-secondarybackground;
            color: $dark-mainText;
        }

        button {
            border-color: $dark-accent;
            color: $dark-accent;
        }

        hr {
            background-color: $dark-secondarybackground;
            border: 1px solid $dark-secondarybackground;
        }

        .posts {
            h2 {
                color: $dark-accent;
                opacity: 0.8;
            }
            .article {
                &:hover {
                    p {
                        border-color: $dark-secondaryText;    
                    }
                }
                .date {
                    color: $dark-accent;
                    opacity: 0.75;
                }
            }
        }

        .works {
            .card {
                background-color: $dark-secondarybackground;
            }
            .card-bottom {
                .links {
                    > * {
                        transition: $transition;
                    }
                    > *:hover {
                        color: $dark-accent;
                    }
                }
            }
        }

        .contact {
            button {
                color: $dark-mainText;
                border-color: $dark-mainText;
                &:hover {
                    color: $dark-accent;
                    border-color: $dark-accent;
                }
            }
        }
    }
    &.sidebar {
        background-color: $dark-secondarybackground;
        color: $dark-secondaryText;

        nav, ul, p {
            background-color: $dark-secondarybackground;
            color: $dark-mainText;
            
            a {
                color: $dark-secondaryText;

                &.active {
                    svg {
                        color: $dark-accent;
                    }
                }
            }
        }

        ul {
            a:hover {
                color: $dark-accent;
            }
        }

        a::after {
            color: $dark-mainText;
        }

        button {
            &:hover {
                svg {
                    color: $dark-accent;
                }
            }
        }
    }
    @media (max-width: 600px) {
        nav {
            a {
                &:hover {
                    background-color: $dark-hover;
                }
            }
        }
    }
}

.light {
    &.page {
        background-color: $light-background;
        color: $light-mainText;

        .themeToggle {
            background: $light-accent;
            color: $light-background;
        }

        input, textarea {
            background-color: $light-secondarybackground;
            color: $light-mainText;
        }

        button {
            border-color: $light-accent;
            color: $light-accent;
        }

        hr {
            background-color: $light-secondarybackground;
            border: 1px solid $light-secondarybackground;
        }

        .posts {
            h2 {
                color: $light-accent;
            }
            .article {
                &:hover {
                    p {
                        border-color: $light-secondaryText;    
                    }
                }
                .date {
                    color: $light-accent;
                }
            }
        }

        .works {
            .card {
                background-color: $light-secondarybackground;
            }
            .card-bottom {
                .links {
                    & > *:hover {
                        color: $light-accent;
                    }
                }
            }
        }

        .contact {
            button {
                color: $light-mainText;
                border-color: $light-secondaryText;
                &:hover {
                    color: $light-accent;
                    border-color: $light-accent;
                }
            }
        }
    }
    &.sidebar {
        background-color: $light-secondarybackground;
        color: $light-secondaryText;

        nav, ul, p {
            background-color: $light-secondarybackground;
            color: $light-mainText;
            
            a {
                color: $light-secondaryText;

                &.active {
                    svg {
                        color: $light-accent;
                    }
                }
            }
        }

        ul {
            a:hover {
                color: $light-accent;
            }
        }

        a::after {
            color: $light-mainText;
        }

        button {
            &:hover {
                svg {
                    color: $light-accent;
                }
            }
        }
    }
    @media (max-width: 600px) {
        nav {
            a {
                &:hover {
                    background-color: $light-hover;
                }
            }
        }
    }
}

@import 'animate.css';
@font-face {
    font-family: "Roboto";
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto";
}
  
body {
    margin: 0;
    min-height: 100vh;
}
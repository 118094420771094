@import '../../vars.scss';

.page {
    min-height: 100vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 160px;
    padding-right: 30px; 
    overflow: auto;
}

.contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    min-height: 90vh;
    padding: 40px;
}

@media (max-width: 600px) {
    .page {
        padding-top: 65px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}


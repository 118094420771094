@import '../../vars.scss';

.themeToggle {
    position: fixed;
    top: 12px;
    right: 12px;

    z-index: 2;

    width: 34px;
    height: 34px;
    
    border-radius: 10px;
    font-size: 20px;
    box-shadow: inset 2px 2px 2px rgba(128, 128, 128, 0.5);

    border: none;
    
    cursor: pointer;

    transition: $transition;

    &:hover {
        filter: brightness(0.9);
    }
    &:active {
        filter: brightness(0.8);
    }
}

@media (max-width: 600px) {
    .themeToggle {
        top: 8px;
        right: 50px;
        height: 30px;
        width: 30px;
    }
}
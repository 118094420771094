@import '../../vars.scss';

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;

    text-align: center;
}

.top {
    display: flex;
    align-items: center;
    gap: 80px;

    > * {
        flex: 1 1 auto;
    }

    .personalPicture {
        background-image: url("./personalPicture.jpg");
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: 0 auto;

        border: 3px solid white;
        box-shadow: 0px 0px 8px;
    }
}

.middle {
    display: flex;
    flex-direction: column;
    gap: 20px;

    max-width: 870px;
}

.bottom {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;

    .skillsContainer {
        h4 {
            margin-bottom: 20px;
        }
    }

    .skills {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 10px;

        .skill {
            width: 100px;
            padding: 10px;
            transition: $transition;
            border-radius: 10px;

            &:hover {
                transform: scale(1.1) rotate(5deg);
                box-shadow: 0px 0px 6px grey;
            }

            img {
                width: 40px;
            }
        }
    }
}

@media (max-width: 1050px) {
    .home {
        gap: 60px;
        width: 90%;
    }
    .top {
        flex-direction: column;
        gap: 20px;
        font-size: 0.7rem;
    }
}

@media (max-width: 420px) {
    .middle {
        font-size: 0.85rem;
    }
    .bottom {
        .skills {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            align-items: center;
        }
    }
}


@import "../../vars.scss";

.contact {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

form {
    ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        row-gap: 20px;
        column-gap: 10px;
        max-width: 500px;
        min-height: 300px;
        margin-bottom: 20px;

        li {
            list-style: none;
            input, textarea {
                padding: 1em 0.7em;
                border-radius: calc($border-radius / 2);
                border: none;
                width: 100%;
                height: 100%;
                resize: none;
            }
            &:nth-child(1) {
                grid-column: span 1;
            }
            &:nth-child(2) {
                grid-column: span 1;
            }
            &:nth-child(3) {
                grid-column: 1 / 3;
            }
            &:nth-child(4) {
                grid-column: 1 / 3;
                grid-row: span 4;
            }
        }
    }
    button {
        padding: 0.5em 1.5em;
        border-radius: calc($border-radius / 2);
        background-color: transparent;
        letter-spacing: $letter-spacing;
        text-transform: uppercase;

        &:active{
            transform: scale(0.99);
        }
    }
}

@media (max-width: 600px) {
    form ul li {
        &:nth-child(1), &:nth-child(2) {
            grid-column: span 2;
        }   
    }
}